import { graphql } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import PageBanner from "../../components/Common/PageBanner";
import { unwrapNode } from "../../utils/markdown-unwrapper";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import SEO from "../components/App/SEO";
import DangerousMarkdownContent from "../components/DangerousMarkdownContent/DangerousMarkdownContent";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { t, language } = useI18next();
  const teamMember = unwrapNode(data.markdownRemark, language);

  return (
    <Layout>
      <SEO title={teamMember.name} />
      <Navbar />
      <PageBanner
        pageTitle={teamMember.name}
        homePageText={t("navigation:team")}
        homePageUrl="/team"
        activePageText={teamMember.name}
      />
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-6 col-xl-5">
            <div className="single-scientist-box">
              <div className="image d-flex justify-content-center ">
                <Img
                  alt={`${teamMember.name} - ${teamMember.role}`}
                  className="team-picture"
                  style={{ height: "100%", width: "100%" }}
                  fluid={teamMember.image.childImageSharp.fluid}
                />
              </div>
              <div className="content">
                <h3>{teamMember.name}</h3>
                <span>{teamMember.role}</span>
                <ul className="social">
                  <li>
                    <a
                      href={`mailto:${teamMember.email}`}
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bx-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={teamMember.linkedinUrl}
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
                {/*<p className="pt-4 font-weight-normal text-left">*/}
                {/*  {teamMember.description}*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-7 d-flex flex-column justify-content-center align-items-center team-member-content">
            <DangerousMarkdownContent content={teamMember.description} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        de {
          role
          name
          email
          linkedinUrl
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        en {
          role
          description
        }
      }
    }
  }
`;
